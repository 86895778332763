import React from 'react';
import Carousel from 'react-elastic-carousel';
import logoIsotim from '../../images/logo-isotim.jpg';
import logoPromantis from '../../images/logo-promantis.png';
import logoScintiEst from '../../images/logo-scinti-est.svg';
import logoSinem from '../../images/logo-sinem.png';
import Item from './Item';

const breakPoints = [
	{ width: 1, itemsToShow: 1 },
	{ width: 550, itemsToShow: 2 },
	{ width: 768, itemsToShow: 3 },
	{ width: 1200, itemsToShow: 4 },
];

const Partner = () => {
	return (
		<div
			style={{
				height: '45vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#592DA0',
			}}>
			<h3 style={{ color: '#fff', fontSize: '2em', marginBottom: '15px' }}>
				Ils nous font confiance
			</h3>
			<Carousel breakPoints={breakPoints}>
				<a
					href="https://www.isotim.fr/"
					target="_blank"
					style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Item>
						<img
							src={logoIsotim}
							alt="Logo Isotim"
							style={{
								maxWidth: '50%',
							}}
						/>
					</Item>
				</a>
				<a
					href="https://www.scinti-est.fr/"
					target="_blank"
					style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Item>
						<img
							src={logoScintiEst}
							alt="Logo Scinti-Est"
							style={{
								maxWidth: '90%',
							}}
						/>
					</Item>
				</a>
				<a
					href="https://www.sinem-saintes.fr/"
					target="_blank"
					style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Item>
						<img
							src={logoSinem}
							alt="Logo Sinem"
							style={{
								maxWidth: '75%',
							}}
						/>
					</Item>
				</a>
				<a
					href="https://promantis.fr/"
					target="_blank"
					style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Item>
						<img
							src={logoPromantis}
							alt="Logo Promantis"
							style={{
								maxWidth: '50%',
							}}
						/>
					</Item>
				</a>
			</Carousel>
		</div>
	);
};

export default Partner;
