import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FaBars } from 'react-icons/fa';
import {
	NavLinks,
	NavbarContainer,
	NavLogo,
	Nav,
	NavMenu,
	NavItems,
	MobileIcon,
	NavBtn,
	NavBtnLink,
} from './NavbarElements';

const Navbar = ({ toggle }) => {
	const [scrollNav, setScrollNav] = useState(false);

	const changeNav = () => {
		if (window.scrollY >= 80) {
			setScrollNav(true);
		} else {
			setScrollNav(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', changeNav);
	}, []);

	const toggleHome = () => {
		scroll.scrollToTop();
	};

	return (
		<>
			{/* <IconContext.Provider value={{ color: '#fff' }}> */}
			<Nav scrollNav={scrollNav}>
				<NavbarContainer>
					<NavLogo to='/' onClick={toggleHome}>
						Ether Healthcare
					</NavLogo>
					<MobileIcon onClick={toggle}>
						<FaBars />
					</MobileIcon>
					<NavMenu>
						<NavItems>
							<NavLinks
								to='about'
								smooth={true}
								duration={500}
								spy={true}
								exact='true'
								offset={-80}
							>
								Qui sommes-nous?
							</NavLinks>
						</NavItems>
						<NavItems>
							<NavLinks
								to='portal'
								smooth={true}
								duration={500}
								spy={true}
								exact='true'
								offset={-80}
							>
								Portail de diffusion
							</NavLinks>
						</NavItems>
						<NavItems>
							<NavLinks
								to='consulting'
								smooth={true}
								duration={500}
								spy={true}
								exact='true'
								offset={-80}
							>
								Consulting
							</NavLinks>
						</NavItems>
						<NavItems>
							<NavLinks
								to='data'
								smooth={true}
								duration={500}
								spy={true}
								exact='true'
								offset={-80}
							>
								Gestion de données
							</NavLinks>
						</NavItems>
						<NavItems>
							<NavLinks
								to='service'
								smooth={true}
								duration={500}
								spy={true}
								exact='true'
								offset={-80}
							>
								Prestations
							</NavLinks>
						</NavItems>
					</NavMenu>
					<NavBtn>
						<NavBtnLink
							to='contact'
							smooth={true}
							duration={500}
							spy={true}
							exact='true'
							offset={-80}
						>
							Contact
						</NavBtnLink>
					</NavBtn>
				</NavbarContainer>
			</Nav>
		</>
	);
};

export default Navbar;
