import styled from 'styled-components';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

export const Label = styled.label`
	color: #592da0;
`;

export const Input = styled.input`
	display: block;
	border: none;
	border-radius: 5px;
	margin-bottom: 10px;
	height: 35px;
	padding: 10px;
	width: 100%;
	font-size: 1rem;
	font-weight: 500;
	color: #2e2e2e;
	outline: none;
`;

export const TextArea = styled.textarea`
	display: block;
	width: 100%;
	min-height: 150px;
	border: none;
	border-radius: 5px;
	font-size: 1rem;
	font-weight: 500;
	outline: none;
	resize: none;
	padding: 10px;
`;

export const BtnWrap = styled.div`
	display: flex;
	justify-content: flex-start;
`;

export const Btn = styled.button`
	border-radius: 25px;
	margin-top: 20px;
	border: none;
	background: #E91E63;
	white-space: nowrap;
	padding: 10px 25px;
	color: #fff;
	font-size: 1.2rem;
	font-weight: 500;

	outline: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transition: all 0.2s ease-in-out;
		background: #592da0;
	}
`;

export const ContactSection = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 20px;
`;

export const ContactLabel = styled.label`
	display: block;
	font-size: 1.1rem;
	font-weight: 600;
	color: #592da0;
`;

export const ContactWrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 10px;
`;

export const Info = styled.a`
	display: block;
	font-size: 1rem;
	font-weight: 600;
	text-decoration: none;
	color: inherit;
`;

export const Icon = styled.img`
	height: 24px;
	width: 24px;
	margin-right: 10px;
	color: #3f3f3f;
`;

export const SocialWrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 10px;
`;

export const SocialIconLink = styled.a`
	color: #3f3f3f;
	font-size: 24px;
	margin-right: 10px;
`;

export const SocialLink = styled.a`
	font-size: 1rem;
	font-weight: 600;
	margin-right: 10px;
	text-decoration: none;
	color: inherit;
`;
