import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md';
import styled from 'styled-components';

export const HeroContainer = styled.div`
	background: #0c0c0c;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 30px;
	height: 100vh;
	position: relative;
	z-index: 1;

	:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
`;

export const HeroBackground = styled.div`
	position: absolute;
	background: #d0c7ed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const VideoBackground = styled.video`
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	overflow: hidden;
`;

export const HeroContent = styled.div`
	z-index: 2;
	max-width: 1200px;
	position: absolute;
	padding: 8px 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const HeroH1 = styled.h1`
	color: #fff;
	font-size: 48px;
	text-align: center;

	@media screen and(max-width: 768px) {
		font-size: 40px;
	}

	@media screen and(max-width: 480) {
		font-size: 32px;
	}
`;

export const HeroP = styled.p`
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
	margin-top: 24px;
	color: #fff;
	text-align: center;
	max-width: 600px;
	margin-bottom: 40px;

	@media screen and(max-width: 768px) {
		font-size: 24;
	}

	@media screen and(max-width: 480) {
		font-size: 18;
	}
`;

export const ArrowForward = styled(MdArrowForward)`
	margin-left: 8px;
	font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
	margin-left: 8px;
	font-size: 20px;
`;
