import ContactForm from '../ContactForm/ContactForm';

export const homeObjZero = {
	id: 'about',
	lightBg: false,
	lightText: false,
	darkText: true,
	topLine: 'Qui sommes-nous?',
	headline: ``,
	description: (
		<p>
			Ether Healthcare est le fruit de 15 années d'expérience dans l'IT médical au sein des plus
			grandes entreprises du secteur, comme Varian Medical System, Carestream ou Fujifilm. Notre
			ambition est d'accompagner les établissements de santé dans leurs besoins d'urbanisation de
			leurs SI.
			<br />
			<br />
			Une analyse fine nous permettra de déterminer les meilleures solutions afin d'atteindre la
			pleine efficience de votre organisation en termes humain, technique, technologique, financier
			et règlementaire.
			<br />
			<br />
			Ensemble, nous bâtirons l'outil parfait pour vous permettre de vous concentrer sur votre
			métier : prendre soin de l'humain.
		</p>
	),
	buttonLabel: `Plus d'infos`,
	imgStart: true,
	img: require('../../images/svg-1.svg').default,
	alt: 'doctors',
	primary: true,
};

export const homeObjOne = {
	id: 'portal',
	lightBg: true,
	lightText: true,
	darkText: true,
	topLine: 'Portail de diffusion',
	headline: `Accès aux données de santé & diagnostic, dans le cloud.`,
	description: (
		<p>
			La communication rapide des comptes-rendus et images aux patients et aux prescripteurs est une
			priorité absolue. Consultez et partagez tous les examens réalisés dans nos établissements
			partenaires, où que vous soyez, le tout en ligne et sécurisé grâce à notre hébergement données
			de santés situé en France.
			<br />
			<br />
			Mise à disposition d'un viewer ultra performant pour la consultation des examens d'imagerie
			médicale.
			<br />
			Partages automatisés des comptes-rendus et images avec les prescripteurs via leur numéro RPPS.
			<br />
			Partage instantané et simple pour les réunions de concertation pluridisciplinaire.
			<br />
			Accès profesionnels de santé facilité et sécurisé grace à Pro Santé Connect.
		</p>
	),
	buttonLabel: `Plus d'infos`,
	imgStart: false,
	img: require('../../images/portal-screen.svg').default,
	alt: 'workflow',
	dark: true,
	primary: true,
};

export const homeObjTwo = {
	id: 'consulting',
	lightBg: false,
	lightText: true,
	darkText: true,
	topLine: 'Conseil',
	headline: `Système d'information`,
	description: (
		<p>
			<li>Audit, analyse des flux, identitovigilance</li>
			<li>
				Intégration contextuelle et intégration HL7, DICOM, FHIR et non standard (txt, csv, etc.)
			</li>
			<li>Anonymisation pour essais cliniques et IA</li>
			<li>Migration de SI et de PACS</li>
			<li>Intégration annuaires Santé (RPPS), ProSantéConnect</li>
			<li>Audit sécurité, RGPD</li>
			<li>
				Assistance à la rédaction des cahiers des charges, d'Appels d'offre en systèmes
				d'information en imagerie médicale
			</li>
		</p>
	),
	buttonLabel: `Plus d'infos`,
	imgStart: true,
	img: require('../../images/svg-8.svg').default,
	alt: 'workflow',
	dark: true,
	primary: true,
};

export const homeObjThree = {
	id: 'consulting',
	lightBg: true,
	lightText: false,
	darkText: true,
	topLine: 'Conseil',
	headline: `RIS & Workflow`,
	description: (
		<p>
			Le rôle du système d'information de radiologie est en pleine mutation à travers l'émergence de
			solutions tierces:
			<li>Prise de rendez-vous à distance</li>
			<li>Bornes d'accueil</li>
			<li>Téléradiologie</li>
			Ether Healthcare vous accompagne dans ces changements afin que vos outils informatiques
			permettent l'exploitation optimale de vos plateaux techniques et fournissent une parfaite
			satisfaction de vos patients et prescripteurs.
			<br />
			Par la même occasion, nous pouvons vous conseiller sur le choix de vos futures briques SI de
			vos solutions et vous informer sur la pérennité d'un investissement.
		</p>
	),
	buttonLabel: `Plus d'infos`,
	imgStart: false,
	img: require('../../images/svg-6.svg').default,
	alt: 'workflow',
	dark: true,
	primary: true,
};

export const homeObjFour = {
	id: 'data',
	lightBg: false,
	lightText: true,
	darkText: true,
	topLine: 'Gestion de données',
	headline: `Archivage & Infrastructure`,
	description: (
		<p>
			<li>Audit, analyse des flux, identitovigilance.</li>
			<li>
				Integration contextuelle et intégration HL7, DICOM, FHIR et non standard (txt, csv, etc.)
			</li>
			<li>Anonymisation pour essais cliniques et IA</li>
			<li>Migration de SI et de PACS.</li>
			<li>Intégration annuaires Santé (RPPS), ProSantéConnect.</li>
			<li>Audit sécurité, RGPD</li>
			<li>
				Assistance à la rédaction des cahiers des charges, d'Appels d'offre en système d'archivage
				d'imagerie médicale
			</li>
		</p>
	),
	buttonLabel: "Plus d'infos",
	imgStart: false,
	img: require('../../images/svg-3.svg').default,
	alt: 'data',
	dark: true,
	primary: true,
};

export const homeObjFive = {
	id: 'service',
	lightBg: true,
	lightText: false,
	darkText: true,
	topLine: `Prestations`,
	headline: `Intégration & Interfaces`,
	description: (
		<p>
			<li>Interfaces HL7 ADT, ORM, SIU, ORU, MDM...</li>
			<li>Transformation CDAr2 pour alimentation du DMP</li>
			<li>Intégration via (s)FTP, SMB, NFS, socket/JSON</li>
			<li>
				Service SMS de notification de rendez-vous, de résultats ou encore authentification à deux
				facteurs (2FA).
			</li>
		</p>
	),
	imgStart: true,
	img: require('../../images/svg-9.svg').default,
	alt: 'contact',
	dark: true,
	primary: true,
};

export const homeObjSix = {
	id: 'contact',
	lightBg: false,
	lightText: true,
	darkText: true,
	topLine: 'Nous contacter',
	headline: `Un projet? Des questions? C'est par ici!`,
	description: <ContactForm />,
	imgStart: false,
	img: require('../../images/svg-7.svg').default,
	alt: 'contact',
	dark: true,
	primary: true,
};
