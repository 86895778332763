import { motion } from 'framer-motion/dist/framer-motion';
import React, { useState } from 'react';
import logo from '../../images/logo-ether.png';
import { Button } from '../ButtonElements';
import {
	ArrowForward,
	ArrowRight,
	HeroBackground,
	HeroContainer,
	HeroContent,
	HeroP,
} from './HeroElements';

const HeroSection = () => {
	const [hover, setHover] = useState(false);
	const onHover = () => {
		setHover(!hover);
	};
	return (
		<HeroContainer>
			<HeroBackground>
				{/* <VideoBackground autoPlay loop muted src={Video} type='video/mp4' /> */}
				<div>
					<motion.div
						initial={{
							opacity: 0,
						}}
						animate={{
							opacity: 1,
							scale: [0, 1],
						}}
						transition={{
							duration: 1.75,
						}}
						style={{
							position: 'absolute',
							top: -300,
							left: -280,
							width: '1000px',
							height: '1000px',
							borderRadius: '50%',
							
							background: '#ffffff20',
						}}
					/>
					<motion.div
						initial={{
							opacity: 0,
							x: 1500,
						}}
						animate={{
							opacity: 1,
							scale: [0, 1],
							x: 0,
						}}
						transition={{
							duration: 1.75,
						}}
						style={{
							position: 'absolute',
							top: 0,
							right: 0,
							width: '600px',
							height: '600px',
							borderRadius: '50%',
							
							background: '#ffffff20',
							zIndex: 1,
						}}
					/>
					<motion.div
						initial={{
							opacity: 0,
							x: -500,
						}}
						animate={{
							opacity: 1,
							scale: [0, 1],
							x: 0,
							y: 0,
						}}
						transition={{
							duration: 1.75,
						}}
						style={{
							position: 'absolute',
							marginTop: 'calc(50vh - 175px)',
							marginLeft: 'calc(50vw - 175px)',
							zIndex: 1,
						}}>
						<img
							src={logo}
							alt="logo"
							style={{
								width: '350px',
								display: 'block',
							}}></img>
					</motion.div>
					<motion.div
						initial={{
							opacity: 0,
							x: -700,
							y: 700,
						}}
						animate={{
							opacity: 1,
							scale: [0, 1],
							x: 0,
							y: 0,
						}}
						transition={{
							duration: 1.75,
						}}
						style={{
							position: 'absolute',
							top: 550,
							right: 450,
							width: '200px',
							height: '200px',
							borderRadius: '50%',
							
							background: '#ffffff20',
						}}
					/>
					<motion.div
						initial={{
							opacity: 0,
							y: -500,
						}}
						animate={{
							opacity: 1,
							scale: [0, 1],
							y: 0,
						}}
						transition={{
							duration: 1.75,
						}}
						style={{
							position: 'absolute',
							top: 700,
							right: 130,
							width: '120px',
							height: '120px',
							borderRadius: '50%',
							
							background: '#ffffff20',
						}}
					/>
					<motion.div
						initial={{
							opacity: 0,
							y: -500,
						}}
						animate={{
							opacity: 1,
							scale: [0, 1],
							y: 0,
						}}
						transition={{
							duration: 1.75,
						}}
						style={{
							position: 'absolute',
							top: '60%',
							right: '30%',
							width: '720px',
							height: '720px',
							borderRadius: '50%',
							
							background: '#ffffff20',
						}}
					/>
				</div>
			</HeroBackground>
			<HeroContent>
				{/* <HeroH1>Ether Healthcare</HeroH1> */}
				<HeroP>Fournisseur de solutions médicales, dans le cloud.</HeroP>
				<Button
					to="about"
					smooth={true}
					duration={500}
					spy={true}
					exact="true"
					offset={-80}
					onMouseEnter={onHover}
					onMouseLeave={onHover}
					primary="true"
					dark="true">
					Qui sommes-nous? {hover ? <ArrowForward /> : <ArrowRight />}
				</Button>
			</HeroContent>
		</HeroContainer>
	);
};
export default HeroSection;
