import React from 'react';
import {
	SidebarContainer,
	Icon,
	CloseIcon,
	SidebarWrapper,
	SideBtnWrap,
	SidebarLink,
	SidebarMenu,
	SidebarRoute,
	SidebarLogo,
} from './SidebarElements';

function Sidebar({ isOpen, toggle }) {
	return (
		<SidebarContainer isOpen={isOpen} onClick={toggle}>
			<SidebarLogo to='/'>Ether Healthcare</SidebarLogo>
			<Icon>
				<CloseIcon />
			</Icon>
			<SidebarWrapper>
				<SidebarMenu>
					<SidebarLink to='about' onClick={toggle}>
						Qui sommes-nous?
					</SidebarLink>
					<SidebarLink to='consulting' onClick={toggle}>
						Consulting
					</SidebarLink>
					<SidebarLink to='data' onClick={toggle}>
						Gestion de données
					</SidebarLink>
					<SidebarLink to='service' onClick={toggle}>
						Prestations
					</SidebarLink>
				</SidebarMenu>
				<SideBtnWrap>
					<SidebarRoute to='contact' onClick={toggle}>
						Nous Contacter
					</SidebarRoute>
				</SideBtnWrap>
			</SidebarWrapper>
		</SidebarContainer>
	);
}

export default Sidebar;
