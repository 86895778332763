import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import {
	Copyright,
	FooterContainer,
	FooterWrapper,
	SocialLogo,
	// FooterLinksContainer,
	// FooterLinksWrapper,
	// FooterItems,
	// FooterLinksTitle,
	// FooterLink,
	SocialMedia,
	SocialMediaWrapper,
} from './FooterElements';
// import { FaLinkedin } from 'react-icons/fa';

const Footer = () => {
	const toggleHome = () => {
		scroll.scrollToTop();
	};
	return (
		<FooterContainer>
			<FooterWrapper>
				{/* <FooterLinksContainer>
					<FooterLinksWrapper>
						<FooterItems>
							<FooterLinksTitle>About Us</FooterLinksTitle>
							<FooterLink to='/'>Link#1</FooterLink>
							<FooterLink to='/'>Link#2</FooterLink>
							<FooterLink to='/'>Link#2</FooterLink>
						</FooterItems>
					</FooterLinksWrapper>
				</FooterLinksContainer> */}
				<SocialMedia>
					<SocialMediaWrapper>
						<SocialLogo to="/" onClick={toggleHome}>
							Ether Healthcare
						</SocialLogo>
						<Copyright>Ether Healtcare ©2021 All rights reserved.</Copyright>
						<a
							href="https://www.linkedin.com/in/yabadine/"
							target={'_blank'}
							style={{ textDecoration: 'none', cursor: 'pointer' }}>
							<h6 style={{ color: 'gray' }}>Réalisation Codevya</h6>
						</a>
					</SocialMediaWrapper>
				</SocialMedia>
			</FooterWrapper>
		</FooterContainer>
	);
};

export default Footer;
