import { Link as LinkScroll } from 'react-scroll';
import styled from 'styled-components';

export const Button = styled(LinkScroll)`
	border-radius: 50px;
	background: ${({ primary }) => (primary ? '#E91E63' : '#010606')};
	white-space: nowrap;
	padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
	color: ${({ dark }) => (dark ? '#fff' : '#010606')};
	font-size: ${({ fontBig }) => (fontBig ? '220px' : '16px')};
	font-weight: 500;
	outline: none;
	border: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;

	&:hover {
		transition: all 0.2s ease-in-out;
		background: ${({ primary }) => (primary ? '#592DA0' : '#eb5b8b')};
		color: ${({ dark }) => (dark ? '#fff' : '#fff')};
	}
`;
