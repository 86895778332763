import styled from 'styled-components';

export default styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 250px;
	width: 100%;
	background-color: #fff;
	color: #3f3f3f;
	margin: 0 15px;
	font-size: 4em;
	border-radius: 15px;
	transition: all 0.2s ease-in-out;

	&:hover {
		filter: brightness(0.75);
		scale: 0.9;
	}
`;
