import React from 'react';
// import { Button } from '../ButtonElements';
import {
	InfoContainer,
	InfoWrapper,
	InfoRow,
	Column1,
	Column2,
	TopLine,
	TextWrapper,
	Heading,
	Content,
	// BtnWrap,
	ImgWrap,
	Img,
} from './InfoElements';

const InfoSection = ({
	lightBg,
	id,
	imgStart,
	topLine,
	headline,
	description,
	lightText,
	darkText,
	buttonLabel,
	btnTarget,
	img,
	alt,
	primary,
	dark,
}) => {
	return (
		<>
			<InfoContainer lightBg={lightBg} id={id}>
				<InfoWrapper>
					<InfoRow imgStart={imgStart}>
						<Column1>
							<TextWrapper>
								<TopLine>{topLine}</TopLine>
								<Heading lightText={lightText}>{headline}</Heading>
								<Content darkText={darkText}>{description}</Content>
							</TextWrapper>
						</Column1>
						<Column2>
							<ImgWrap>
								<Img src={img} alt={alt} />
							</ImgWrap>
						</Column2>
					</InfoRow>
				</InfoWrapper>
			</InfoContainer>
		</>
	);
};

export default InfoSection;
