import pkg from '../package.json'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
console.log(`version: ${pkg.version}`)

Sentry.init({
	dsn: "https://glet_32c8f5b1f3fb7fdba05aa63f75522d29@gitlab.com/api/v4/error_tracking/collector/30035900",
	integrations: [new Integrations.BrowserTracing()],

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);
