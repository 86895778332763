import React, { useState } from 'react';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import {
	homeObjFive,
	homeObjFour,
	homeObjOne,
	homeObjSix,
	homeObjThree,
	homeObjTwo,
	homeObjZero,
} from '../components/InfoSection/Data';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
// import Services from '../components/Services';
import Footer from '../components/Footer';
import Partner from '../components/PartnerCarousel/Partner';

const Home = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<Sidebar isOpen={isOpen} toggle={toggle} />
			<Navbar toggle={toggle} />
			<HeroSection />
			<InfoSection {...homeObjZero} />
			<InfoSection {...homeObjOne} />
			<InfoSection {...homeObjTwo} />
			<InfoSection {...homeObjThree} />
			<InfoSection {...homeObjFour} />
			<InfoSection {...homeObjFive} />
			{/* <Services /> */}
			<Partner />
			<InfoSection {...homeObjSix} />
			<Footer />
		</>
	);
};

export default Home;
