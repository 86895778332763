import { Link as LinkRouter } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import styled from 'styled-components';

export const Nav = styled.nav`
	background: ${({ scrollNav }) => (scrollNav ? '#1f1f1f' : 'transparent')};
	height: 80px;
	margin-top: -80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	position: sticky;
	top: 0;
	z-index: 3;
	transition: 0.8s all ease;

	@media screen and (max-width: 960px) {
		transition: 0.8s all ease;
	}
`;

export const NavbarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: 80px;
	z-index: 2;
	width: 100%;
	padding: 0 0px;
	max-width: 75%;
`;

export const NavLogo = styled(LinkRouter)`
	color: #fff;
	justify-self: flex-start;
	cursor: pointer;
	font-size: 1.5rem;
	font-weight: 500;
	text-decoration: none;
	display: flex;
	align-items: center;
	margin-left: 24px;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);

	@media screen and (max-width: 768px) {
		font-size: 1.5rem;
		margin-left: 0;
	}
`;

export const MobileIcon = styled.div`
	display: none;

	@media screen and (max-width: 768px) {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
		color: #fff;
		z-index: 1;
	}
`;

export const NavMenu = styled.ul`
	display: flex;
	align-items: center;
	list-style: none;
	text-align: center;
	margin-right: -10px;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const NavItems = styled.li`
	height: 80px;
	list-style: none;
`;
export const NavLinks = styled(LinkScroll)`
	color: #fff;
	display: flex;
	align-items: center;
	text-decoration: none;
	font-size: 1rem;
	font-weight: 500;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	transition: 0.4s;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);

	&:hover {
		color: #e91e63;
	}

	&.active {
		border-bottom: 3px solid #e91e63;
	}
`;

export const NavBtn = styled.nav`
	display: flex;
	align-items: center;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const NavBtnLink = styled(LinkScroll)`
	border-radius: 15px 25px 25px 0;
	border: none;
	background: #e91e63;
	white-space: nowrap;
	padding: 10px 15px;
	color: #fff;
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	outline: none;
	cursor: pointer;
	text-decoration: none;

	&:hover {
		background: #e91e63;
		color: #fff;
	}
`;
