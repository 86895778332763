import styled from 'styled-components';

export const InfoContainer = styled.div`
	color: #fff;
	background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#d9e1f7')};

	@media screen and (max-width: 768px) {
		padding: 100px 0;
	} ;
`;

export const InfoWrapper = styled.div`
	display: grid;
	z-index: 1;
	min-height: 860px;
	width: 100%;
	max-width: auto;
	margin-right: auto;
	margin-left: auto;
	padding: 24px;
	justify-content: center;
`;

export const InfoRow = styled.div`
	display: grid;
	grid-auto-columns: minmax(auto, 1fr);
	align-items: center;
	grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
	}
`;

export const Column1 = styled.div`
	margin-bottom: 15px;
	padding: 0 50px;
	grid-area: col1;
`;

export const Column2 = styled.div`
	margin-bottom: 15px;
	padding: 0 50px;
	grid-area: col2;
`;

export const TextWrapper = styled.div`
	max-width: 540px;
	padding-top: 0;
	padding-bottom: 60px;
	padding-top: 60px;
`;

export const TopLine = styled.h3`
	color: #e91e63;
	font-size: 1.5rem;
	line-height: 24px;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 20px;
`;

export const Heading = styled.h2`
	margin-bottom: 20px;
	font-size: 2rem;
	line-height: 1.1;
	font-weight: 600;
	color: ${({ lightText }) => (lightText ? '#592DA0' : '#010606')};

	@media screen and (max-width: 480px) {
		font-size: 32px;
	}
`;

export const Content = styled.div`
	max-width: 640px;
	margin-bottom: 35px;
	font-size: 18px;
	font-weight: 600;
	line-height: 30px;
	color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
`;

export const BtnWrap = styled.div`
	display: flex;
`;

export const ImgWrap = styled.div`
	display: flex;
	justify-content: flex-start;
	padding: 50px;
`;

export const Img = styled.img`
	width: 100%;
	margin: 0 0 10px 0;
	padding-right: 0;
`;
