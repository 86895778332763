import { useState } from 'react';
import {
	Btn,
	BtnWrap,
	ContactLabel,
	ContactSection,
	ContactWrapper,
	Form,
	Info,
	Input,
	Label,
	SocialIconLink,
	SocialLink,
	SocialWrapper,
	TextArea,
} from './ContactFormElements';
import { FaLinkedin, FaMailBulk, FaPhone } from 'react-icons/fa';

const ContactForm = () => {
	const [status, setStatus] = useState('Envoyer');
	const handleSubmit = async (e) => {
		e.preventDefault();
		setStatus('Envoie en cours...');
		const { name, email, message } = e.target.elements;
		let details = {
			name: name.value,
			email: email.value,
			message: message.value,
		};
		const response = await fetch(`${process.env.REACT_APP_API_URL}/mail/contact?from=website`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
			},
			body: JSON.stringify(details),
		});
		setStatus('Envoyé!');
		if (response.status === 201) {
			setStatus('Envoyer');
		}
	};
	return (
		<Form onSubmit={handleSubmit}>
			<Label htmlFor="name">Nom:</Label>
			<Input type="text" id="name" required />

			<div>
				<Label htmlFor="email">Email:</Label>
				<Input type="email" id="email" required />
			</div>
			<div>
				<Label htmlFor="message">Message:</Label>
				<TextArea id="message" placeholder="Votre message" required />
			</div>
			<BtnWrap>
				<Btn type="submit">{status}</Btn>
			</BtnWrap>
			<ContactSection>
        <ContactLabel>Directeur commercial - Johan Zoli</ContactLabel>
        <ContactWrapper>
					<SocialIconLink href="tel:+33663320619" target="_blank" aria-label="Phone" noopener>
						<FaPhone />
					</SocialIconLink>
					<Info href="tel:+33663320619">06 63 32 06 19</Info>
				</ContactWrapper>
				<ContactWrapper>
					<SocialIconLink
						href="mailto:contact@ether.healthcare"
						target="_blank"
						aria-label="LinkedIn"
						noopener>
						<FaMailBulk />
					</SocialIconLink>{' '}
					<Info href="mailto:contact@ether.healthcare">contact@ether.healthcare</Info>
				</ContactWrapper>
				

				<SocialWrapper>
					<SocialIconLink
						href="https://fr.linkedin.com/in/johanzoli"
						target="_blank"
						aria-label="LinkedIn"
						noopener>
						<FaLinkedin />
					</SocialIconLink>
					<SocialLink
						href="https://fr.linkedin.com/in/johanzoli"
						target="_blank"
						aria-label="LinkedIn"
						noopener>
						LinkedIn
					</SocialLink>
				</SocialWrapper>
			</ContactSection>
		</Form>
	);
};

export default ContactForm;
